import classNames from "classnames";
import { type Badge } from "@/components/common/badge/badge";
import { STORY_VARIANTS, type StoryVariant } from "../StoryContent";

export type HeadingProps = {
  badges?: Badge[];
  isActiveStory: boolean;
  title: string;
  variant: StoryVariant;
};

export const StoryHeading = ({
  badges,
  isActiveStory,
  title,
  variant,
}: HeadingProps) => {
  let titleClasses = "";

  switch (variant) {
    case STORY_VARIANTS.FOLLOW: {
      titleClasses = "headline-s lg:headline-l xl:headline-xl font-medium";
      break;
    }
    case STORY_VARIANTS.LEAD: {
      titleClasses = "headline-l lg:headline-l xl:headline-xl font-medium";
      break;
    }
    case STORY_VARIANTS.NEBULA: {
      titleClasses =
        "leading-5 sm:text-[1.125rem] sm:leading-[1.375rem] md:text-[1.25rem] md:leading-[1.5rem] lg:leading-[1.625rem] font-semibold lg:font-[550] text-inkwell";
      break;
    }
    case STORY_VARIANTS.SHOPPING: {
      titleClasses = "lg:headline-m md:headline-l headline-m font-semibold";
      break;
    }
    default: {
      titleClasses =
        "headline-s sm:headline-m md:headline-l xl:headline-xl font-semibold";
      break;
    }
  }

  return (
    <h3
      className={classNames(
        "stretched-box group-hover:underline",
        {
          "line-clamp-3 text-base sm:text-lg md:line-clamp-4 md:text-xl":
            variant === STORY_VARIANTS.NEBULA,
        },
        badges || variant === STORY_VARIANTS.SHOPPING
          ? "line-clamp-3"
          : "line-clamp-4",
        isActiveStory && "!text-dolphin",
        titleClasses,
      )}
    >
      {title}
    </h3>
  );
};
