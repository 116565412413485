"use client";

import { I13nAnchor } from "@yahoo-creators/i13n";
import { I13nLink } from "@yahoo-creators/i13n/components/I13nLink";
import { type YlkData } from "@yahoo-creators/i13n/types/ylk";
import { usePathname } from "next/navigation";
import { useEffect, useState, type FC, type ReactNode } from "react";
import { useConfigContext } from "@/contexts/ConfigContext";
import { beaconError } from "@/lib/beacon";
import { useRequestContext } from "@/lib/request/client";

interface Props {
  children: ReactNode;
  className?: string;
  disableModal?: boolean;
  href?: string;
  dataYlk: YlkData;
  onClick?: Function;
  target?: string;
}

export const ArticleLink: FC<Props> = ({
  dataYlk = {},
  children,
  disableModal,
  href,
  onClick,
  ...rest
}) => {
  const [isModalNav, setIsModalNav] = useState(false);
  const pathname = usePathname();
  const {
    bot,
    features: { modal },
    searchParams: { ncid },
  } = useRequestContext();
  const { articleLink: { disableModal: globalDisableModal } = {} } =
    useConfigContext();

  const search = ncid ? `?ncid=${ncid}` : "";

  useEffect(() => {
    if (!isModalNav || pathname !== "/") {
      return;
    }

    const onBeforeUnload = () => {
      const error: any = new Error("Modal navigation failure");
      error.ylk = dataYlk;
      error.href = href;
      delete error.stack; // don't need the stack for this error
      beaconError("modal_failure", error);
    };

    addEventListener("beforeunload", onBeforeUnload);
    return () => removeEventListener("beforeunload", onBeforeUnload);
  }, [isModalNav, dataYlk, href, pathname]);

  if (!href) {
    console.error({ dataYlk, msg: "MISSING_HREF" });
  }

  if (!modal || !href || disableModal || globalDisableModal || bot) {
    return (
      <I13nAnchor dataYlk={dataYlk} href={href} {...rest}>
        {children}
      </I13nAnchor>
    );
  }

  const url = new URL(href);
  const isExternal = !url.hostname.endsWith(".yahoo.com");

  if (isExternal) {
    console.error("External url was passed to ArticleLink", href);
    return (
      <I13nAnchor
        dataYlk={dataYlk}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        {...rest}
      >
        {children}
      </I13nAnchor>
    );
  }

  // use relative urls for "modal"/client side navigation
  const subdomain = url.hostname.split(".")[0];
  const { pathname: urlPathname, hash = "" } = url;
  let relativeUrl = `${urlPathname}${search}${hash}`;

  // handle finance, autos, and sports subdomains and set them as the first path part
  if (["autos", "finance", "sports"].includes(subdomain)) {
    relativeUrl = `/${subdomain}${relativeUrl}`;
  }

  return (
    <I13nLink
      dataYlk={dataYlk}
      href={relativeUrl}
      prefetch={true}
      {...rest}
      onClick={(e) => {
        if (!e.altKey && !e.metaKey) {
          setIsModalNav(true);
          if (onClick) {
            onClick();
          }
        }
      }}
    >
      {children}
    </I13nLink>
  );
};
