import classNames from "classnames";
import { useEffect, useState, useRef, type FC, memo, useContext } from "react";
import { ArticleLink } from "@/components/common/ArticleLink";
import { ImageTags } from "@/configs/ncp";
import { useNcpCacheContext } from "@/contexts/NcpCacheContext";
import { useBadges } from "@/hooks/useBadges";
import { COMMON_STREAM_LINK_I13N_PARAMS } from "@/lib/i13n";
import { I13nStartIndexContext } from "@/lib/I13nStartIndexContext";
import { type StoryItem as StoryItemType } from "@/lib/streamDataUtils";
import { getBadgeI13n, STREAM_BADGE_ALLOWLIST } from "../../common/badge/badge";
import { NCPImage } from "../NCPImage";
import {
  STORY_VARIANTS,
  type StoryVariant,
  StoryContent,
} from "./StoryContent";

const containerClasses = classNames([
  "block",
  "border-batcave/15",
  "group",
  "last:border-none",
  "lg:last:pb-0",
  "relative",
  "xl:pr-5",
]);

export const gridClasses = classNames(
  "col-span-full",
  "grid",
  "grid-cols-[110px_auto]",
  "sm:grid-cols-2",
  "lg:grid-cols-9",
  "xl:grid-cols-[292px_auto]",
  "gap-x-3",
  "sm:gap-x-4",
);

type Props = StoryItemType & {
  i13nSubsec?: string;
  selectedStory: string;
  extraClassNames?: string;
  variant?: StoryVariant;
};

export const StoryItem: FC<Props> = memo(
  ({
    attribution,
    badges,
    bypassModal,
    cacheId,
    categoryLabel,
    commentCount,
    extraClassNames = "",
    i13nSubsec,
    id,
    selectedStory,
    summary,
    thumbnail,
    title,
    url,
    variant = STORY_VARIANTS.DEFAULT,
    viewCount,
    wpm200,
  }) => {
    const i13nStartIndex = useContext(I13nStartIndexContext);
    const filteredBadges = useBadges({
      allowList: STREAM_BADGE_ALLOWLIST,
      badges,
    });

    const [isActiveStory, setIsActiveStory] = useState(false);
    const hasStoryPreviouslyRendered = useRef(false);

    useEffect(() => {
      /* only want to set active story if it matches story cacheId
    but only on first render of story component (aka page load)
    mark story as rendered so function cannot be ran again when
    selected story changes */
      if (!hasStoryPreviouslyRendered.current) {
        const isSelectedStory = cacheId === selectedStory;
        if (isSelectedStory) {
          setIsActiveStory(true);
        }
        hasStoryPreviouslyRendered.current = true;
      }
    }, [selectedStory, cacheId]);

    const { articlePosition, setArticlePosition } = useNcpCacheContext();

    const resumePosition = () => {
      setArticlePosition({
        ...articlePosition,
        stream: cacheId || "",
      });
    };

    const selectedImageOverlayClasses = [
      "before:absolute",
      "before:w-full",
      "before:h-full",
      "before:top-0",
      "before:left-0",
      "before:z-[1]",
      "before:transition-all",
      "before:duration-500",
      "before:ease-in-out",
      "before:bg-gradient-to-b",
      "before:from-transparent",
      "before:to-black",
      "before:opacity-0",
      {
        "before:opacity-90": isActiveStory,
      },
    ];

    return (
      <li className={classNames(containerClasses, extraClassNames)}>
        <ArticleLink
          href={url}
          className={classNames(gridClasses)}
          dataYlk={{
            cnt_tpc: categoryLabel,
            cpos: i13nStartIndex + 1,
            g: id,
            ll4: getBadgeI13n(badges),
            pos: 1,
            subsec: i13nSubsec,
            ...COMMON_STREAM_LINK_I13N_PARAMS,
          }}
          disableModal={bypassModal}
          onClick={resumePosition}
        >
          <div
            className={classNames(
              selectedImageOverlayClasses,
              "pointer-events-none relative lg:col-span-4 xl:col-auto",
            )}
          >
            <NCPImage
              alt=""
              className="relative size-[110px] rounded sm:hidden"
              image={thumbnail}
              size={ImageTags.XS}
            />
            <NCPImage
              alt=""
              className="relative hidden size-full rounded object-cover sm:block"
              image={thumbnail}
              size={ImageTags.MD}
            />
          </div>
          <div className="flex min-w-0 flex-1 flex-col gap-1.5 sm:gap-2 lg:col-span-5 xl:col-auto">
            <StoryContent
              attribution={attribution}
              badges={filteredBadges}
              bypassModal={bypassModal}
              cacheId={cacheId}
              categoryLabel={categoryLabel}
              commentCount={commentCount}
              id={id}
              isActiveStory={false}
              summary={summary}
              title={title}
              url={url}
              variant={variant}
              viewCount={viewCount}
              wpm200={wpm200}
            />
          </div>
        </ArticleLink>
      </li>
    );
  },
);
