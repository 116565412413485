/**
 * Capture group 1: /creators
 * Capture group 2: rest of path
 */
const BRAND_PATH_REGEX = /(\/creators)?(\/\S+)/;

/**
 * Capture group 1: Section (e.g, "lifestyle")
 * Capture group 2: Article slug, including .html suffix
 */
const ARTICLE_PATH_REGEX = /\/(\S+)\/(\S+\.html)/;

/**
 * Convert a given article URL string to a relative path by removing
 * the protocol and domain.
 *
 * If conversion fails, return the original URL.
 *
 * @param url the URL string to convert
 * @returns a relative path version of the given URL string
 */
export function articleUrlToRelativePath(
  url: string | undefined,
): string | undefined {
  if (!url || !ARTICLE_PATH_REGEX.test(url)) {
    return url;
  }
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname.match(ARTICLE_PATH_REGEX)?.[0]!;
    return pathname + parsedUrl.search;
  } catch {
    return url;
  }
}

/**
 * Convert a given brand URL string to a relative path by removing
 * the protocol and domain, as well as the "/creators" prefix if present.
 *
 * If conversion fails, return the original URL.
 *
 * @param url the URL string to convert
 * @returns a relative path version of the given URL string
 */
export function brandUrlToRelativePath(
  url: string | undefined,
): string | undefined {
  if (!url || !BRAND_PATH_REGEX.test(url)) {
    return url;
  }
  try {
    const parsedUrl = new URL(url);
    // Remove "/creators" from the path, if present.
    const pathnameWithoutCreators =
      parsedUrl.pathname.match(BRAND_PATH_REGEX)?.[2]!;
    // Enforce trailing slash for brand URLs.
    const pathPart = pathnameWithoutCreators.endsWith("/")
      ? pathnameWithoutCreators
      : pathnameWithoutCreators + "/";
    return pathPart + parsedUrl.search;
  } catch {
    return url;
  }
}
