import NextImage from "next/image";
import { useEffect, useState, type FC } from "react";
import { type StreamImage } from "@/components/shared/Stream/data";

type Props = {
  alt?: string;
  className?: string;
  image?: StreamImage | null;
  url?: string;
  priority?: boolean;
  size?: string;
  style?: React.CSSProperties;
};

const getRequestedDimensions = (tag: string): [number, number] => {
  const dimensions = tag.split("|")[0];
  const [widthStr, heightStr] = dimensions.split("x");
  const [width, height] = [parseInt(widthStr), parseInt(heightStr)];
  return !Number.isNaN(width) || !Number.isNaN(height)
    ? [width, height]
    : [0, 0];
};

const NCPImage: FC<Props> = ({
  alt = "",
  className,
  image,
  url,
  priority,
  size = "original",
  style,
}) => {
  let fallbackSrc =
    "https://s.yimg.com/cv/apiv2/rocket/ImagePlaceholder16_9.png";
  let resizedImage = image?.resolutions?.find((res) => size === res?.tag);
  let requestedWidth, requestedHeight;
  if (!resizedImage) {
    // Use requested dimensions for placeholder
    [requestedWidth, requestedHeight] = getRequestedDimensions(size);
  }

  // https://solutions-image-fallback.vercel.app/
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [resizedImage?.url]);

  const { height, width } = resizedImage || {
    height: requestedHeight,
    width: requestedWidth,
  };

  const imageSrc = resizedImage?.url ?? url;

  return (
    <NextImage
      alt={alt}
      className={className}
      height={height}
      onError={() => setError(true)}
      priority={priority}
      src={error ? fallbackSrc : imageSrc || fallbackSrc}
      unoptimized
      width={width}
      style={style}
    />
  );
};
export { NCPImage };
