"use client";

import { type DeviceSize } from "@yahoo-news/util";
import { useEffect } from "react";
import { I13N_KEYS_TO_CLEAR, MINIMUM_GUCE_CONSENT_AGE } from "@/configs/benji";
import { useUserBenjiVars } from "@/hooks/useUserBenjiVars";
import { getBenjiConsent } from "@/lib/i13n";

export const useBenjiConfig = ({
  benjiConfig,
  clearKeys,
  deviceSize,
  enableAdStacking,
  refreshLimit,
  taboolaSetting,
}: {
  benjiConfig?: { i13n: object };
  clearKeys?: boolean;
  deviceSize?: DeviceSize | null;
  enableAdStacking?: boolean;
  refreshLimit?: number;
  taboolaSetting?: object;
}) => {
  const {
    ageBucket,
    ageInt,
    bot,
    genderString,
    hydrated: personalizedDataHydrated,
    userConsents,
    userCountry,
    userJurisdiction,
  } = useUserBenjiVars();

  useEffect(() => {
    if (
      !deviceSize ||
      !personalizedDataHydrated ||
      (ageInt > 0 && ageInt < MINIMUM_GUCE_CONSENT_AGE)
    ) {
      return;
    }

    const url = new URL(window.location.href);

    const i13n = {
      ...benjiConfig?.i13n,
      adLite: "0", // TODO YNCX-794
      adblock: "0", // TODO
      app: "", // TODO
      authed: ageInt > 0 ? "1" : "0", // Use age as a proxy for authed
      bka: String(ageBucket),
      bkg: genderString,
      // TODO "browser"
      bot,
      device: deviceSize,
      gdpr: userJurisdiction === "GDPR",
      lu: ageInt > 0 ? "1" : "0", // Use age as a proxy for authed
      // TODO "os"
      ssl: window.location.href.startsWith("https:") ? "1" : "0",
      time: Date.now(),
      tpConsent:
        bot === "1" || !userConsents
          ? true
          : userConsents?.includes("THIRD_PARTY_CONTENT_EMBED"),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      url: `${url.origin}${url.pathname}`,
      usercountry: userCountry,
    };

    if (!window.benji?.isReady) {
      const config = {
        ...benjiConfig,
        event: {},
        feature: {
          enableAdStacking,
          enableEdgeToEdge: true,
          enableRefreshDebounce: true,
          enableYahooPrebid: true,
        },
        i13n,
        positions: {},
        setting: {
          consent: getBenjiConsent(userJurisdiction, userConsents),
          lazyLoad: false,
          refresh: {
            duration: 20,
            limit: refreshLimit,
            requireUserAction: false,
            sameSizeRefresh: true,
            tabFocus: {
              outOfFocusDuration: 3,
            },
          },
          renderOnStart: false,
          taboolaSetting,
          tracking: {
            debug: true,
            metrics: true,
            performance: true,
          },
        },
      };

      if (typeof window.benji?.start === "function") {
        window.benji.start(config);
      } else {
        window.benji = { config };
      }
    } else {
      window.benji.updateI13N(i13n, clearKeys ? I13N_KEYS_TO_CLEAR : undefined);
    }
  }, [
    ageBucket,
    ageInt,
    benjiConfig,
    bot,
    clearKeys,
    deviceSize,
    enableAdStacking,
    genderString,
    personalizedDataHydrated,
    refreshLimit,
    taboolaSetting,
    userConsents,
    userCountry,
    userJurisdiction,
  ]);
};
