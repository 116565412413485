import {
  type CaasArticle,
  type CaasAtom,
  type CaasImageAtom,
} from "@/types/Caas";

export const isPrestigeArticle = (article: CaasArticle): boolean =>
  article.data?.contentMeta?.contentIdentifiers?.presentation === "prestige";

export const isPrestigeFullBleed = (article: CaasArticle): boolean =>
  article.data?.contentMeta?.contentImages?.heroModule?.type ===
  "full-viewport";

export const isCreatorContent = (article: CaasArticle): boolean =>
  article.data?.contentMeta?.contentIdentifiers?.isCreatorContent ?? false;

/**
 * Checks if the given {@link CaasAtom} is a {@link CaasImageAtom}.
 * Looks for the two required properties of the required `size` object, `original` and `resized`.
 */
export function isValidCaasImageAtom(atom: CaasAtom): atom is CaasImageAtom {
  return (
    !!(atom as CaasImageAtom).size?.original &&
    !!(atom as CaasImageAtom).size?.resized
  );
}
