import { StoryMetadata } from "@/components/shared/StoryMetadata/StoryMetadata";
import { useRequestContext } from "@/lib/request/client";
import { type StoryItem } from "@/lib/streamDataUtils";
import { Attribution } from "../../../common/Attribution/Attribution";
import { Badges } from "../../../common/Badges";
import { StoryHeading } from "../StoryHeading";
import { Stats } from "./Stats";

export const STORY_VARIANTS = {
  DEFAULT: "DEFAULT",
  FOLLOW: "FOLLOW",
  LEAD: "LEAD",
  NEBULA: "NEBULA",
  SHOPPING: "SHOPPING",
};

export type StoryVariants = keyof typeof STORY_VARIANTS;
export type StoryVariant = (typeof STORY_VARIANTS)[StoryVariants];

const Summary = ({
  text,
  variant,
}: {
  text: string;
  variant: StoryVariant;
}) => {
  switch (variant) {
    case STORY_VARIANTS.LEAD: {
      return (
        <p className="body-m hidden text-batcave sm:line-clamp-2">{text}</p>
      );
    }
    case STORY_VARIANTS.NEBULA: {
      return (
        <p className="hidden text-[0.875rem] font-[450] leading-[1.188rem] text-battleship sm:line-clamp-2 md:hidden lg:text-[#6a6a6a]">
          {text}
        </p>
      );
    }
    case STORY_VARIANTS.SHOPPING: {
      return null;
    }
    default: {
      return (
        <p className="body-m hidden text-batcave lg:line-clamp-2">{text}</p>
      );
    }
  }
};

type Props = StoryItem & {
  isActiveStory: boolean;
  variant?: StoryVariant;
};

export const StoryContent = ({
  attribution,
  badges,
  commentCount,
  isActiveStory,
  summary,
  title,
  variant = STORY_VARIANTS.DEFAULT,
  viewCount,
  wpm200,
}: Props) => {
  const { features } = useRequestContext();
  const showCreatorLogo = variant === STORY_VARIANTS.NEBULA;

  return (
    <>
      <div className="flex">
        {badges.length ? (
          <Badges badges={badges} showCreatorLogo={showCreatorLogo} />
        ) : null}
        {variant === STORY_VARIANTS.NEBULA && (
          <div className="hidden min-w-0 pl-2.5 sm:flex">
            <StoryMetadata
              attribution={attribution}
              commentCount={commentCount}
              fontStyles="font-[450]"
            />
          </div>
        )}
      </div>
      <StoryHeading
        badges={badges}
        isActiveStory={isActiveStory}
        title={title}
        variant={variant}
      />
      <Summary text={summary} variant={variant} />
      {variant === STORY_VARIANTS.NEBULA ? (
        <div className="flex flex-row items-center sm:hidden">
          <StoryMetadata
            attribution={attribution}
            commentCount={commentCount}
            fontStyles="font-[450]"
          />
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <Attribution
            authors={attribution.authors}
            image={attribution.image}
            source={attribution.source}
          />
          {features.moreForYouHideStats || (
            <Stats
              commentCount={commentCount}
              viewCount={viewCount}
              wpm200={wpm200}
            />
          )}
        </div>
      )}
    </>
  );
};
