/* eslint-disable sort-keys */

import { type Bundle } from "ycb";
import * as benji from "./benji";

export const Features = {
  /**
   * Set creator brand state to active
   * @author rcoughlin
   */
  activateBrand: null,

  /**
   * Add source test/control to affiliate link for tracking Amazon rev. The feature flag will remove after commerceR12NCluster finish
   * @author gracelin
   */
  affiliateLinkWithTestSource_ssr: null,
  affiliateLinkWithControlSource_ssr: null,

  /**
   * Enable Gen AI Summary in article view
   * @author vsampath
   */
  aiSummary: null,

  /**
   * Reduce length of Gen AI Summary in article view
   * @author vsampath
   */
  aiSummaryShort: null,

  /**
   * Enable animatinng search labels in uh search bar
   * @author ssirpilli
   */
  animateSearchBar: null,

  /**
   * Enable Apollo GraphQL based query
   * @author vsampath
   */
  apolloQuery: null,

  /**
   * Enable Leading the News module in article view
   * This disables the topic subnavigation
   * @author amadan
   */
  articleTrendingTopics: null,

  /**
   * Enable use of more for you stream from astronomer for generic
   * @author dweaver01
   */
  astroStreamGeneric: null,

  /**
   * Enable use of more for you stream from astronomer for lifestyle
   * @author dweaver01
   */
  astroStreamLifestyle: null,

  /**
   * Test if caas will honor the bot query param in production
   * @author kfay
   */
  botParam: null,

  /**
   * Enable bottom sticky ad
   * @author akumar06
   */
  bottomStickyAd: null,

  /**
   * Enable Feeds based off categories for recirc modules
   * @author cmorri
   */
  categoryFeedRecirc: null,

  /**
   * Enable Card layout for recirc
   * @author vsampath
   */
  cardRecirc: null,

  /**
   * Enable Category filters for Card recirc
   * @author amadan
   */
  cardRecircFilters: null,

  /**
   * Enable Chartbeat realtime metrics tracking
   * @author chitrat
   */
  chartbeat: null,

  /**
   * Enable comments module for articles
   * @author weihsuehl
   */
  comments: null,

  /**
   * Enable commerce list
   * @author vsampath
   */
  commerceForceList: null,

  /**
   * Enable commerce seamless cluster
   * @author gracelin
   */
  commerceR12NCluster: null,

  /**
   * Hide top center mobile ad on commerce articles
   * @author dliu01
   */
  commerceRemoveTopCenterMobileAd: null,

  /**
   * Add source test/control to affiliate link for tracking Amazon rev. The feature flag will remove after commerceRemoveTopCenterMobileAd finish
   * @author thuang04
   */
  commerceRemoveTopCenterMobileAdControlSource_ssr: null,
  commerceRemoveTopCenterMobileAdTestSource_ssr: null,

  /**
   * Enable Comscore UDM 2.0 beacons
   * @author sbertal
   */
  comscoreUdm2: null,

  /**
   * Nebula desktop with NTK carousel
   * @author nford
   */
  conBar: null,

  /**
   * AI Chat in ConBar
   * @author suneil
   */
  conBarAi: null,

  /**
   * Today In History in ConBar
   * @author hannahb01
   */
  conBarHistory: null,

  /**
   * Weather in ConBar
   * @author suneil
   */
  conBarWeather: null,

  /**
   * What to watch in ConBar
   * @author suneil
   */
  conBarWtw: null,

  /**
   * Enable Yahoo Creators article layout purple variant
   * @author crombach
   */
  creatorsArticlePurple_ssr: null,

  /**
   * Yahoo Creators article layout with recirc articles by category
   * @author crombach
   */
  creatorsRecircCategory: null,
  /**
   * Yahoo Creators article layout with recirc articles by creator
   * @author crombach
   */
  creatorsRecircCreator: null,

  /**
   * Enable dark mode support
   * @author suneil
   */
  darkmode: null,

  /**
   * Display mid center ads on modal pages for desktop
   * @author mghanshala
   */
  desktopInBodyAd: null,

  /**
   * Enable taboola ads in NTK Hero Carousel
   * @author hannahb01
   */
  enableNTKHeroCarouselAds: null,

  /**
   * Enable PFS for Universal Profile
   * @author dsinclair
   */
  enablePFS: null,

  /**
   * Enable Taboola Lazy Fetch
   * @author akumar06
   */
  enableTaboolaLazyFetch: null,

  /**
   * Two RR ads instead of 9 + remove the second billboard ad
   * @author nbodani
   */
  enableTwoRRAdsMoreForYou: null,

  /**
   * New universal header for Homepage
   * @author etast
   */
  expheader: null,

  /**
   * Enable Inactivity recirc on article pages
   * @author vsampath
   */
  inactivityRecirc: null,

  /**
   * Enable Inactivity recirc on article pages with the most-popular-in-category data
   * @author vsampath
   */
  inactivityRecircMostPopular: null,

  /**
   * Enable Inactivity recirc on article pages with the related-content-stream data
   * @author vsampath
   */
  inactivityRecircRelatedContent: null,

  /**
   * Enable first 5 articles in more for you Jarvis powered stream to use goldlist
   * @author bwalsh02
   */
  jarvisMoreForYouGoldList: null,

  /**
   * Enable lazy loading of ads
   * @author clicata
   */
  lazyAds: null,

  /**
   * Enable homepage modal
   * @author bwalsh02
   */
  modal: null,

  /**
   * Enable moreForYouHideStats (comment count, view count, readtime) module on homepage more for you stream
   * @author dweaver01
   */
  moreForYouHideStats: null,

  /**
   * Enable larger images for mweb breakpoints homepage stream
   * @author bwalsh02
   */
  mwebStreamLargerImages: null,

  /**
   * Nebula desktop with NTK carousel
   * @author bwalsh02
   */
  nebulaDwebCarousel: null,

  /**
   * Display next article bar
   * @author cmorri
   */
  nextArticleBar: null,

  /**
   * Enable NTK dweb Carousel
   * @author sfreeman01
   */
  ntkHeroCarousel: null,

  /**
   * Enable live blog headline marquee in NTK
   * @author hannahb01
   */
  ntkLiveBlog: null,

  /**
   * Nebula grid with image background
   * @author nbodani
   */
  nebulaNtkGridCarousel: null,

  /**
   * Enable open telemetry for the browser
   * @author suneil
   */
  otelBrowser: null,

  /**
   * Enable perf debug bar
   * @author suneil
   */
  perf: null,

  /**
   * Enable preview mode for editors
   * @author kfay
   */
  preview: null,

  /**
   * Enable Games promo.
   * @author imcdonagh
   */
  promoGames: null,

  /**
   * Enable Games health promo.
   * @author imcdonagh
   */
  promoGamesHealth: null,

  /**
   * Enable Games promo and suppress ad below promo.
   * @author imcdonagh
   */
  promoGamesNoAd: null,

  /**
   * Enable Games promo for Crossword.
   */
  promoGamesCrossword: null,

  /**
   * Enable Games promo for Mahjong Solitaire.
   */
  promoGamesMahjongSolitaire: null,

  /**
   * Enable Games promo for Mahjong Solitaire.
   */
  promoGamesSolitaireClassic: null,

  /**
   * Blue Links for Quick Overview and Mini Modules for bucket testing, will also be added to source test/control to affiliate link for revenue tracking .
   * @author timliao
   */
  quickOverviewBlueLinkBucketControlSource_ssr: null,
  quickOverviewBlueLinkBucketTestSource_ssr: null,

  /**
   * Enable seamless article scrolling
   * @author dweaver
   */
  seamless: null,

  /**
   * Show feedback banner on nebula
   * @author nbodani
   */
  showFeedbackBanner: null,

  /**
   * Show mail badge on homepage for UH mail icon
   * @author etast
   */
  showMailBadge: null,

  /**
   * Show mail gradient on homepage for UH mail icon
   * @author etast
   */
  showMailGradient: null,

  /**
   * Show mail knockout treatment for UH mail icon
   * @author etast
   */
  showMailKnockout: null,

  /**
   * Enable old yahoo slimbar module
   * @author nford
   */
  showSlimJimOptOut: null,

  /**
   * Show taboola ad in center-well recirc
   * @author vsampath
   */
  showCenterWellRecircAd: null,

  /**
   * Show slottedCarousel module on homepage
   * @author sfreeman01
   */
  slottedCarousel: null,

  /**
   * Show trending video module in stream
   * @author hannahb01
   */
  slottedTrendingVideo: null,

  /**
   * Show slottedVerticalVideo module on homepage
   * @author adesai01
   */
  slottedVerticalVideo: null,

  /**
   * Enable specialEvent module homepage
   * @author nford
   */
  specialEvent: null,

  /**
   * Enable Taboola realtime metrics tracking
   * @author chitrat
   */
  taboolaRealTimeTracking: null,

  /**
   * Enable Topic Subnavigation in article view (Client side)
   * @author amadan
   */
  topicSubnavClient: null,

  /**
   * Enable Rollover Menu's for UH Nav items
   * @author ssirpilli
   */
  uhRolloverMenus: null,

  /**
   * Enable UH Search styles with gradient border and button hover effect
   * @author che
   */
  uhSearchGradientStyle: null,

  /**
   * Enable support for video items in Nebula Stream
   * @author sfreeman01
   */
  videoInStream: null,

  /**
   * Nebula right rail weather with greeting
   * @author sfreeman01
   */
  weatherCardV2: null,

  /**
   * Enable webp images
   * @author suneil
   */
  webp: null,

  /**
   * Tappable webstories
   * https://ouryahoo.atlassian.net/browse/YNCX-1536
   * @author suneil amadan
   */
  webstories_ssr: null,

  /**
   * ynet
   * @author rcoughlin
   */
  ynet: null,

  /**
   * Pass through for benji features
   */
  ...benji.FEATURES,
};

export type FeaturesMap = Partial<Record<keyof typeof Features, boolean>>;

export type FeaturesConfig = {
  features: FeaturesMap;
};

export const config: Bundle<FeaturesConfig> = [
  {
    settings: ["main"],
    features: {
      aiSummary: true,
      bottomStickyAd: true,
      cardRecirc: true,
      chartbeat: true,
      conBarWeather: true,
      desktopInBodyAd: true,
      expheader: true,
      modal: true,
      otelBrowser: true,
      promoGames: true,
      promoGamesCrossword: true,
      seamless: true,
      showCenterWellRecircAd: true,
      specialEvent: true,
    },
  },
  {
    settings: {
      dimensions: ["lang:en-US"],
    },
    features: {
      comments: true,
    },
  },
];
