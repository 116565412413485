import classNames from "classnames";
import Image from "next/image";
import { type FC } from "react";

type Props = {
  className?: string;
  imageUrl: string;
};

export const AttributionImage: FC<Props> = ({ className, imageUrl }) => {
  return (
    <Image
      alt=""
      className={classNames(className, "mr-1.5 inline size-5 rounded-full")}
      src={imageUrl}
      height="20"
      unoptimized
      width="20"
    />
  );
};
